import { useTheme, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PROBONIO_MAIL } from '../../constants';
const footerLinks = {
  imprint: PROBONIO_MAIL,
  dataProtection: 'https://www.probonio.de/datenschutz',
  help: 'https://support.probonio.de/hc/de',
};
export const LoginFooter = () => {
  const theme = useTheme();
  const { t } = useTranslation('authModule');
  return (
    <Box
      sx={{
        backgroundColor: 'transparent',
        padding: 0.5,
        paddingBottom: 2,
        textAlign: 'center',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignSelf: 'end',
        gap: 1,
        width: '100%',
      }}
    >
      {Object.entries(footerLinks).map(([key, link]) => (
        <a
          key={key}
          href={link}
          target="_blank"
          rel="noreferrer"
          style={{ color: theme.palette.primary.main, textDecoration: 'none', fontSize: 14, cursor: 'pointer' }}
        >
          {t(`footer.${key}`)}
        </a>
      ))}
    </Box>
  );
};

export default LoginFooter;
